<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style>
@font-face {
  font-family: "Lato";
  src: local("Inter"),
    url(../src/assets/fonts/Lato-Regular.ttf) format("truetype");
}

.background{
  background: #0C111D;
}
</style>

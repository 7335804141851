<template>
  <div class="landing">
    <div class="info">
      <div>
        <img
          src="@/assets/images/pophr.svg"
          alt="logo"
        >
      </div>
      <div class="intro">
        <p class="soon">
          SË SHPEJTI
        </p>
        <h1 class="exclusive">
          Njoftohu ekskluzivisht <br>
          për zhvillimet e fundit 🚀
        </h1>
        <div
          class="fields"
          style="margin-bottom: 10px;"
        >
          <form @submit.prevent="subscribeUser">
            <label for="email">Email</label>
            <div class="inputButtonWrapper">
              <div class="inputButton">
                <input
                  v-model="email"
                  type="email"
                  required
                >
                <button
                  type="submit"
                  class="notify"
                >
                  Më njofto
                </button>
              </div>
              <span class="spam">*Pa merak nuk do të dërgojmë spam emails</span>
            </div>
          </form>
        </div>
        <!-- Success Message -->
        <p
          v-if="successMessage"
          class="success-message"
        >
          {{ successMessage }}
        </p>
      </div>
    </div>
    <div class="image">
      <img
        src="@/assets/images/poplanding.svg"
        alt="landing"
      >
    </div>
    <span class="copyright">@ POP-HR 2024</span>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      successMessage: '', // Add success message data property
    };
  },
  methods: {
    async subscribeUser() {
      try {
        const response = await axios.post('https://api.brevo.com/v3/contacts', {
          email: this.email,
          listIds: [3], // Replace with your actual list ID
          updateEnabled: true, // Optional: Set to true if you want to update the contact if it already exists
        }, {
          headers: {
            'api-key': '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', // Replace with your actual API key
            'Content-Type': 'application/json',
          },
        });
        console.log('User subscribed:', response.data);
        // Set success message and clear the email input
        this.successMessage = 'You have subscribed successfully!';
        this.email = '';
      } catch (error) {
        console.error('Error subscribing user:', error.response?.data || error.message);
      }
    },
  },
}
</script>

<style scoped>
.landing {
  display: flex;
  width: 100%;
  height: 100vh;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  width: 50%;
  box-sizing: border-box;
  position: relative;
}

.inputButton {
  display: flex;
}

.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  text-align: left;
  padding-left: 2%;
}

.input-group {
  display: flex;
  align-items: center;
}

.fields input {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  height: 44px;
  flex-grow: 1;
  width: 300px;
  margin-right: 10px;
}

.notify {
  background: #3643CC;
  border-radius: 8px;
  height: 44px;
  width: 110px;
  color: white;
  border: none;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: bold;
}

.copyright {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-size: 14px;
  color: #888;
}

.image {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.soon {
  font-size: 29px;
  color: #A5A5A5;
}

.exclusive {
  font-size: 42px;
  font-weight: 700;
  color: #101828;
}

.spam {
  font-size: 16px;
  color: #475467;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .exclusive {
    font-size: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .exclusive {
    font-size: 25px;
  }
  .inputButton {
    display: flex;
    flex-direction: column;
  }
  .notify {
    margin-top: 10px !important;
  }
  .soon {
    font-size: 23px;
    color: #A5A5A5;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .landing {
    flex-direction: column;
  }

  .exclusive {
    font-size: 23px;
  }

  .soon {
    font-size: 21px;
    color: #A5A5A5;
  }

  .spam {
    font-size: 14px;
    color: #475467;
  }

  .info {
    padding: 20px;
  }

  .inputButton {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    width: 100%;
    align-items: center; */
  }

  .inputButton input {
    width: 100%;
  }

  .info img {
    width: 70%;
  }
  
  .info, .image {
    width: 100%;
    height: auto;
  }

  .logo, .copyright {
    left: 50%;
    bottom: 0%;
    transform: translateX(-50%);
  }

  .notify {
    margin-top: 10px !important;
  }

  .fields {
    width: 100%;
  }
  
  .intro {
    width: 100%;
    margin-top: 8%;
    padding-left: 0px;
  }
}
</style>


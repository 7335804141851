<template>
  <div>
    <ComingSoon />
  </div>
</template>

<script>
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  components: {
    ComingSoon
  }
}
</script>

<style>

</style>
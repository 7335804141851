import { createRouter, createWebHistory } from "vue-router";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "SpimApp",
    component: HomePage,
    meta: {
      title: "Empowering Business Growth with SPIM | SPIM - Streamline Your Operations",
      metaTags: [
        { name: "description", content: "Empower your business growth with SPIM, the ultimate solution for streamlining operations, managing reports, tracking tasks, and organizing employee information seamlessly. Join many companies benefiting from SPIM's customizable dashboard, report management, task management, employee information management, integration capabilities, access control, notifications, and data security features." },
        { property: "og:description", content: "Empower your business growth with SPIM, the ultimate solution for streamlining operations, managing reports, tracking tasks, and organizing employee information seamlessly. Join many companies benefiting from SPIM's customizable dashboard, report management, task management, employee information management, integration capabilities, access control, notifications, and data security features." }
      ]
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

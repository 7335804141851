import { createApp } from "vue";
import VueSplide from "@splidejs/vue-splide";
import AOS from "aos";
import { createI18n } from "vue-i18n";
import en from "./i18n/en.json";
import de from "./i18n/de.json";
import fr from "./i18n/fr.json"
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import VueCarousel from 'vue-carousel';
// import { VueMarqueeSlider } from 'vue3-marquee-slider';
import '../node_modules/vue3-marquee-slider/dist/style.css'
// Vue.use(VueCarousel);


import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
const i18n = createI18n({
  locale: store.state.language.lang,
  // fallbackLocale: "de",
  messages: {
    en,
    de,
    fr,
  },
});
app.use(i18n);
app.use(store);
app.use(router);
app.use(VueSplide);


app.mount("#app");

// Initialize AOS (if necessary)
AOS.init();
